import { defineStore, acceptHMRUpdate } from 'pinia';

interface State {
  active: boolean;
  code: number | null;
  text: string | null;
}

export const useStoreAppErrorIndex = defineStore({
    id: 'app/error/index',

    state: (): State => ({
        active: false,
        code: 404,
        text: 'Страница не найдена',
    }),

    actions: {
        setError(data: { code: number, text: string}| null) {
            if (data === null) {
                this.active = false;
                this.code = null;
                this.text = null;
            } else {
                this.active = true;
                this.code = data.code ?? 404;
                this.text = data.text ?? 'Страница не найдена';
            }
        },
    },
});
if (import.meta.hot) {
    import.meta.hot.accept(
        acceptHMRUpdate(useStoreAppErrorIndex, import.meta.hot),
    );
}
